<template>
  <ul class="pay-list m-bottom-sm">
    <li>
      <b>账户名称</b>
      <span>北京火星火翎文化科技有限公司</span>
    </li>
    <li>
      <b>银行账户</b>
      <span>100010124824</span>
    </li>
    <li>
      <b>开户行</b>
      <span>友利银行（中国）有限公司</span>
    </li>
  </ul>
</template>

<script>

  export default {
    data() {
      return {
      }
    },

    components: {
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .pay-list {
    width: 100%;
    padding: 20px 0;
    background-color: #fffbf8;

    li {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      b {
        display: inline-block;
        width: 40%;
        text-align: right;
        padding-right: 20px;
      }

      span {
        display: inline-block;
        width: 60%;
        text-align: left;
        padding-left: 20px;
      }
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .pay-list {
      li {
        b {
          width: 30%;
          padding-right: 10px;
        }

        span {
          width: 70%;
          padding-left: 10px;
        }
      }
    }
  }
</style>
