<template>
  <div class="content">
    <slot name="extra" :item="payInfo"></slot>

    <div class="m-bottom-sm top-text">请按照以下信息进行对公转账付款</div>

    <com-to-com-pay-info></com-to-com-pay-info>

    <div class="m-bottom-md">付款成功后请点击按钮，我们会帮助完成订单</div>

    <a-popconfirm trigger="click" :icon="null" @confirm="onConfirm">
      <template slot="title">
        <div class="text-center lh-1-5">
          付款成功后请向客服提交订单号和付款凭证截图
          <br />我们会帮助完成订单
        </div>
      </template>

      <a-button
        class="pay-btn"
        size="large"
        type="primary"
        :loading="loading"
        @click="visible = true;"
      >我已付款</a-button>
    </a-popconfirm>
  </div>
</template>

<script>
  import {Popconfirm} from 'ant-design-vue';
  import ComToComPayInfo from './ComToComPayInfo.vue'

  export default {
    data() {
      return {
        loading: false,
        payInfo: {},
      }
    },
    props: {
      order_id: String,
      order_type: String,
    },

    computed: {
      request() {
        return this.order_type === this.$val.orderType.package ? this.$axios.PayPackage_Com : this.$axios.PayOrder_Com;
      }
      // imgUrl() {
      //   return this.payInfo && this.payInfo.pay_data ? this.payInfo.pay_data : '';
      // }
    },

    components: {
      APopconfirm: Popconfirm,
      ComToComPayInfo,
    },

    methods: {
      onConfirm() {
        let params = {
          order_id: this.order_id
        };

        this.loading = true;

        this.request(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$emit('success', this.$val.OrderStatus.doingPay);
          }

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .content {
    width: 630px;
    margin: 0 auto;
    text-align: center;

    .top-text {
      text-align: left;
    }

    .pay-list {
      padding: 20px 0;
      background-color: #fffbf8;

      li {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        b {
          display: inline-block;
          width: 40%;
          text-align: right;
          padding-right: 20px;
        }

        span {
          display: inline-block;
          width: 60%;
          text-align: left;
          padding-left: 20px;
        }
      }
    }

    .pay-btn {
      border-radius: 7px;
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .content {
      width: 100%;
    }
  }
</style>
