<template>
  <div class="flex flex-column cen-center content">
    <slot name="extra" :item="payInfo"></slot>

    <a-spin :spinning="loading">
      <div class="code-img-box m-bottom-xs">
        <img
          v-if="imgUrl && codeStatus === $var.Load.end"
          class="code-img"
          :src="imgUrl"
          @error="onErrorCode"
        />
        <div
          v-else-if="codeStatus === $var.Load.error"
          :class="['error-box', reloading ? 'loading' : '']"
        >
          <div class="m-bottom-xs">获取二维码失败</div>
          <div class="font-color-primary cursor-pointer" @click="onReload">
            <icon-font class="icon-load" type="iconload"></icon-font>
            <span>重新加载</span>
          </div>
        </div>
      </div>
    </a-spin>

    <div>打开手机微信，扫一扫付款</div>
  </div>
</template>

<script>
  import {Spin} from 'ant-design-vue';

  import payMixins from '@/mixins/pay';

  export default {
    data() {
      return {
        reloading: false,
        payInfo: {},
        isErrorCode: false,

        loading: false,

        codeStatus: this.$var.Load.init,
      }
    },
    props: {
      order_id: String,
      order_type: String,
    },

    mixins: [payMixins],

    watch: {
      order_id: {
        handler(newVal) {
          if (newVal) {
            this.getPayDetailOuter(newVal);
          }
        },
        immediate: true
      }
    },

    computed: {
      imgUrl() {
        return this.payInfo && this.payInfo.pay_data ? this.payInfo.pay_data : '';
      }
    },

    components: {
      ASpin: Spin
    },

    methods: {
      onErrorCode() {
        this.codeStatus = this.$var.Load.error;
      },

      onReload() {
        this.loading = true;
        this.isErrorCode = false;

        this.cancelCheck();
        this.endTimer();
        this.getPayDetailOuter(this.order_id);
      },

      getPayDetailOuter(order_id) {
        let pay_way = this.$val.PayType.wx;

        this.loading = true;
        this.codeStatus = this.$var.Load.loading;

        this.getPayDetail({order_id, pay_way}).then(data => {
          this.payInfo = Object.assign({}, data);
          this.codeStatus = this.$var.Load.end;
          this.loading = false;
        }).catch(() => {
          this.isErrorCode = true;
          this.codeStatus = this.$var.Load.error;
          this.loading = false;
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  .code-img-box {
    border: 1px solid $border-color;

    .code-img {
      width: 100%;
      height: 100%;
    }
  }

  .error-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .icon-load {
      margin-right: 5px;
    }

    &.loading {
      .icon-load {
        transform: rotate(360deg);
        transition: transform linear 0.2s;
      }
    }
  }

  @media screen and(min-width: $screen-width-md) {
    .code-img-box {
      width: 200px;
      height: 200px;
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .code-img-box {
      width: 15rem;
      height: 15rem;
    }
  }
</style>
