export default {
  data() {
    return {
      payLoading: false,
    }
  },

  beforeDestroy() {
    this.cancelCheck();

    this.endTimer();
  },

  methods: {
    checkPayStatus(params) {
      let requestFunc = this.order_type && this.order_type === this.$val.orderType.package ? this.$axios.PayPackageCheck : this.$axios.PayCheck;

      return new Promise(resolve => {
        this.request = requestFunc(params, (c) => {
          this.cancelRequestFunc = c;
        }).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let pay_status = data.data ? data.data.pay_status : '';

            if (pay_status == '1') {
              resolve(data)
              this.$emit('success', data.data);
            } else {
              this.reCheck(params);
            }
          }
        })
      })
    },

    reCheck(params) {
      this.endTimer();

      this.timer = setTimeout(() => {
        this.checkPayStatus(params);
      }, 1000);
    },

    cancelCheck() {
      typeof this.cancelRequestFunc === 'function' && this.cancelRequestFunc();
    },

    endTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },

    reGetPayDetail(params) {
      this.cancelCheck();
      this.endTimer();
      this.getPayDetail(params)
    },

    getPayDetail(params) {
      let requestFunc = this.order_type && this.order_type === this.$val.orderType.package ? this.$axios.PayPackage : this.$axios.PayOrder;

      this.payLoading = true;

      return new Promise((resolve, reject) => {
        requestFunc(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let payData = data.data;
            let check_id = payData.check_id ? payData.check_id : ''

            resolve(data.data);

            this.endTimer();

            check_id && this.checkPayStatus({
              check_id
            });
          } else {
            reject();
          }

          this.payLoading = false;
        }).catch(() => {
          this.payLoading = false;
          reject();
        })
      })
    }
  }
}