<template>
  <a-row class="pay-way">
    <template v-for="item in PayWay">
      <a-col
        :xs="24"
        :sm="24"
        :md="8"
        :class="['pay-way-item', curKey === item.key ? 'active' : '']"
        @click="onClickItem(item.key)"
        :key="item.key"
      >
        <ng-image class="img" :src="item.img"></ng-image>
        <span>{{item.name}}</span>
      </a-col>
    </template>
  </a-row>
</template>

<script>
  const PayWay = (PayType) => {
    return [
      {
        name: '支付宝付款',
        key: PayType.zfb,
        img: require('@/assets/images/alipay.png')
      },
      {
        name: '微信付款',
        key: PayType.wx,
        img: require('@/assets/images/wechat.png')
      },
      {
        name: '公对公付款',
        key: PayType.gdg,
        img: require('@/assets/images/companyPay.png')
      }
    ]
  }

  export default {
    data() {
      return {
        curKey: '',
        PayWay: PayWay(this.$val.PayType)
      }
    },
    components: {

    },

    methods: {
      onClickItem(key) {
        this.curKey = key;

        this.$root.$goSearch({
          payway: key
        })

        this.$emit('change', key);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pay-way {
    .pay-way-item {
      height: 108px;
      line-height: 108px;
      border: 1px solid $border-color;
      font-size: 16px;
      text-align: center;
      cursor: pointer;

      &.active {
        position: relative;
        border-color: $black-3;
        z-index: 2;
      }

      .img {
        width: 48px;
        // height: 48px;
        margin-right: 16px;
      }
    }
  }

  @media screen and (min-width: $screen-width-md) {
    .pay-way {
      width: 100%;
      margin: 0 auto;

      .pay-way-item {
        margin-right: -1px;
      }
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .pay-way {
      width: 100%;

      .pay-way-item {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
